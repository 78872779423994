<template>
  <div id="requisitions" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >REQUISICIONES DE NUEVO PERSONAL</v-row
      >
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addRequisition" id="nuevaempresa">
              <button class="breakSearch botonAmarillo">
                Agregar Requisición
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="requisiciones"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Requisiciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{ item.puestoVacante.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      @click="viewItem(item)"
                      v-if="item.estatusId != 1"
                      >fa-eye</v-icon
                    >
                    <v-icon
                      v-if="item.estatusId == 1"
                      color="#717171"
                      small
                      class="mr-3"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      v-if="item.estatusId == 1"
                      color="red"
                      small
                      @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empresa</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      address: [],
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Vacante",
          align: "center",
          value: "puestoVacante.nombrePuesto",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus.estatus",
        },
        {
          text: "Acciones",
          value: "estatus",
          align: "center",
          sortable:false
        },
      ],
      requisiciones: [],
      enterprises: [],
      enterpriseSelect: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      idEmpleado: localStorage.empleadoIdGlobal,
    };
  },
  methods: {
    editItem(item) {
      localStorage.requisicionId = item.id;
      localStorage.empresaId = item.puestoVacante.empresaId;
      localStorage.puestoId = item.puestoVacante.id;
      console.log(item);
      this.$router.push("/finishRequisition");
    },
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la postulante de " +
        item.puestoVacante.nombrePuesto +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/requisicionesVacantes/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta = "La vacante fue eliminada con éxito";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "La vacante no fue eliminada con éxito.";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      localStorage.requisicionId = item.id;
      this.$router.push("/resume")
    },
    listar() {
      this.show = true;
      alert(this.idEmpleado+ " el id del empleado");
      axios
        .get(
          Server + "/requisicionesVacantes/por-empleado/" + this.idEmpleado,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.requisiciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>