<template>
        <div id="procesos-seleccion" class="contenedor">
        <loader :show="show" />
        <sesion :expiration="expiration" />
        <Sidebar></Sidebar>
        <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >Lista Entrevista</v-row>
        </v-container>
        <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="requisiciones"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Requisiciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{ item.puestoVacante.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      @click="viewItem(item)"
                      v-if="item.estatusId != 1"
                      >fa-eye</v-icon
                    >
                    <v-icon
                      v-if="item.estatusId == 1"
                      color="#717171"
                      small
                      class="mr-3"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      v-if="item.estatusId == 1"
                      color="red"
                      small
                      @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
    components: {
    Sidebar,
    Loader,
    Sesion,
  },
    data() {
        return {
            show: false,
            expiration: false,
            headers: [
                { text: "Nº", value: "id" },
                { text: "Candidato", value: "puestoVacante.nombrePuesto", sortable: true},
                { text: "Fecha de Ingreso", value: "estatus.estatus", sortable: true},
                { text: "Estatus", value: "semaforo", sortable: false },
                { text: "Evaluación", value: "actions", sortable: false },
                { text: "Entrevista ", value: "Entrevista", sortable: false },
            ],
        }
    },
    methods: {
    },
    created() {
        this.show = true;
         // terminar loader
        this.show = false;
        
    },
};
</script>
