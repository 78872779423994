<template>
    <div id="procesos-seleccion" class="contenedor">
        <loader :show="show" />
        <sesion :expiration="expiration" />
        <Sidebar></Sidebar>
        <v-container fluid class="base text-center">
           <v-row no-gutters class="titulo ml-10 mt-5"><span style="color:black">Entrevista RH para el puesto :  {{puestoTitulo}}</span></v-row>
           <v-container fluid class="addForms" style="margin-top: 2% !important">
            <v-row no-gutters justify="end">
                <v-col cols="6" lg="5" md="5" sm="5" class="marginCol formularios">
                    <v-row>
                        <div class="form-group">
                            <label >Nombre</label>
                            <input v-model="nombre" type="text" name="nombre" id="nombre" placeholder="Obligatorio" class="inputs" autocomplete="false"required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div> 
                        <div class="form-group">
                            <label >Apellido Paterno</label>
                            <input v-model="apellidoPaterno" type="text" name="apellidoPaterno" id="apellidoPaterno" placeholder="Obligatorio" class="inputs" autocomplete="false"required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div>
                        <div class="form-group">
                            <label >Apellido Materno</label>
                            <input v-model="apellidoMaterno" type="text" name="apellidoMaterno" id="apellidoPaterno" placeholder="Obligatorio" class="inputs" autocomplete="false"required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div> 
                        <div class="form-group">
                            <label for="cvPdf">CV Pdf</label>
                            <input  type="file" name="cvPdf" id="cvPdf" placeholder="Obligatorio" class="inputs" autocomplete="false" required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div>
                        <div class="form-group">
                            <label for="telefono">Telefono</label>
                            <input v-model="telefono" type="phone" name="telefono" id="telefono" placeholder="Obligatorio" class="inputs" autocomplete="false" required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div>
                        <div class="form-group">
                            <label for="email">Correo</label>
                            <input v-model="email" type="email" name="email" id="email" placeholder="Obligatorio" class="inputs" autocomplete="false" required maxlength="60" @keypress="alfaNumerico($event)" />
                        </div>
                    </v-row> 
                </v-col>
            </v-row>
            <v-row>
                <div style="margin: 25px;">
                        
                        <p>Funciones 
                            <table>
                                <tr>
                                    <td colspan="5">
                                        <div class="satisfaction-bar"  style="margin: 5px;">
                                            <div class="level very-dissatisfied" style="padding: 1px;"><span v-if="calificaFunciones == 1"><font-awesome-icon size="1x" style="color:gray; " icon="angry" /></span></div>
                                            <div class="level dissatisfied" style="padding: 1px;"><span v-if="calificaFunciones == 2"><font-awesome-icon size="1x" style="color:gray;" icon="frown" /></span></div>
                                            <div class="level neutral" style="padding: 1px;"><span v-if="calificaFunciones == 3"><font-awesome-icon size="1x" style="color:gray;" icon="meh" /></span></div>
                                            <div class="level satisfied" style="padding: 1px;"><span v-if="calificaFunciones == 4"><font-awesome-icon size="1x" style="color:gray;" icon="smile" /></span></div>
                                            <div class="level very-satisfied" style="padding: 1px;"><span v-if="calificaFunciones == 5"><font-awesome-icon size="1x" style="color:gray;" icon="grin" /></span></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedFuncion === 'angry' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="angry" 
                                            @click="selectedFuncion = 'angry'; calificaFunciones = 1;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedFuncion === 'frown' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="frown" 
                                            @click="selectedFuncion = 'frown'; calificaFunciones = 2;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedFuncion === 'meh' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="meh" 
                                            @click="selectedFuncion = 'meh'; calificaFunciones = 3;" 
                                            />
                                        
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedFuncion === 'smile' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedFuncion = 'smile'; calificaFunciones = 4;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedFuncion === 'grin' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedFuncion = 'grin'; calificaFunciones = 5;" 
                                            />
                                    </td>
                                </tr>
                            </table>

                            </p>
                        <p>Habilidades
                            <table>
                                <tr>
                                    <td colspan="5">
                                        <div class="satisfaction-bar"  style="margin: 5px;">
                                            <div class="level very-dissatisfied" style="padding: 1px;"><span v-if="calificaHabilidades == 1"><font-awesome-icon size="1x" style="color:gray; " icon="angry" /></span></div>
                                            <div class="level dissatisfied" style="padding: 1px;"><span v-if="calificaHabilidades == 2"><font-awesome-icon size="1x" style="color:gray;" icon="frown" /></span></div>
                                            <div class="level neutral" style="padding: 1px;"><span v-if="calificaHabilidades == 3"><font-awesome-icon size="1x" style="color:gray;" icon="meh" /></span></div>
                                            <div class="level satisfied" style="padding: 1px;"><span v-if="calificaHabilidades == 4"><font-awesome-icon size="1x" style="color:gray;" icon="smile" /></span></div>
                                            <div class="level very-satisfied" style="padding: 1px;"><span v-if="calificaHabilidades == 5"><font-awesome-icon size="1x" style="color:gray;" icon="grin" /></span></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHabilidades === 'angry' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="angry" 
                                            @click="selectedHabilidades = 'angry'; calificaHabilidades = 1;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHabilidades === 'frown' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="frown" 
                                            @click="selectedHabilidades = 'frown'; calificaHabilidades = 2;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHabilidades === 'meh' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="meh" 
                                            @click="selectedHabilidades = 'meh'; calificaHabilidades = 3;" 
                                            />
                                        
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHabilidades === 'smile' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedHabilidades = 'smile'; calificaHabilidades = 4;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHabilidades === 'grin' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedHabilidades = 'grin'; calificaHabilidades = 5;" 
                                            />
                                    </td>
                                </tr>
                            </table>
                        </p>
                        <p>Idioma
                            <table>
                                <tr>
                                    <td colspan="5">
                                        <div class="satisfaction-bar"  style="margin: 5px;">
                                            <div class="level very-dissatisfied" style="padding: 1px;"><span v-if="calificaIdioma == 1"><font-awesome-icon size="1x" style="color:gray; " icon="angry" /></span></div>
                                            <div class="level dissatisfied" style="padding: 1px;"><span v-if="calificaIdioma == 2"><font-awesome-icon size="1x" style="color:gray;" icon="frown" /></span></div>
                                            <div class="level neutral" style="padding: 1px;"><span v-if="calificaIdioma == 3"><font-awesome-icon size="1x" style="color:gray;" icon="meh" /></span></div>
                                            <div class="level satisfied" style="padding: 1px;"><span v-if="calificaIdioma == 4"><font-awesome-icon size="1x" style="color:gray;" icon="smile" /></span></div>
                                            <div class="level very-satisfied" style="padding: 1px;"><span v-if="calificaIdioma == 5"><font-awesome-icon size="1x" style="color:gray;" icon="grin" /></span></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedIdioma === 'angry' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="angry" 
                                            @click="selectedIdioma = 'angry'; calificaIdioma = 1;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedIdioma === 'frown' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="frown" 
                                            @click="selectedIdioma = 'frown'; calificaIdioma = 2;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedIdioma === 'meh' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="meh" 
                                            @click="selectedIdioma = 'meh'; calificaIdioma = 3;" 
                                            />
                                        
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedIdioma === 'smile' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedIdioma = 'smile'; calificaIdioma = 4;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedIdioma === 'grin' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedIdioma = 'grin'; calificaIdioma = 5;" 
                                            />
                                    </td>
                                </tr>
                            </table>
                        </p>
                        <p>Herramientas
                            <table>
                                <tr>
                                    <td colspan="5">
                                        <div class="satisfaction-bar"  style="margin: 5px;">
                                            <div class="level very-dissatisfied" style="padding: 1px;"><span v-if="calificaHerramienta == 1"><font-awesome-icon size="1x" style="color:gray; " icon="angry" /></span></div>
                                            <div class="level dissatisfied" style="padding: 1px;"><span v-if="calificaHerramienta == 2"><font-awesome-icon size="1x" style="color:gray;" icon="frown" /></span></div>
                                            <div class="level neutral" style="padding: 1px;"><span v-if="calificaHerramienta == 3"><font-awesome-icon size="1x" style="color:gray;" icon="meh" /></span></div>
                                            <div class="level satisfied" style="padding: 1px;"><span v-if="calificaHerramienta == 4"><font-awesome-icon size="1x" style="color:gray;" icon="smile" /></span></div>
                                            <div class="level very-satisfied" style="padding: 1px;"><span v-if="calificaHerramienta == 5"><font-awesome-icon size="1x" style="color:gray;" icon="grin" /></span></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHerramienta === 'angry' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="angry" 
                                            @click="selectedHerramienta = 'angry'; calificaHerramienta = 1;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedherramienta === 'frown' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="frown" 
                                            @click="selectedHerramienta = 'frown'; calificaHerramienta = 2;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHerramienta === 'meh' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="meh" 
                                            @click="selectedHerramienta = 'meh'; calificaHerramienta = 3;" 
                                            />
                                        
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHerramienta === 'smile' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedHerramienta = 'smile'; calificaHerramienta = 4;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedHerramienta === 'grin' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedHerramienta = 'grin'; calificaHerramienta = 5;" 
                                            />
                                    </td>
                                </tr>
                            </table>
                        </p>
                        <p>Salario
                            <table>
                                <tr>
                                    <td colspan="5">
                                        <div class="satisfaction-bar"  style="margin: 5px;">
                                            <div class="level very-dissatisfied" style="padding: 1px;"><span v-if="calificaSalario == 1"><font-awesome-icon size="1x" style="color:gray; " icon="angry" /></span></div>
                                            <div class="level dissatisfied" style="padding: 1px;"><span v-if="calificaSalario == 2"><font-awesome-icon size="1x" style="color:gray;" icon="frown" /></span></div>
                                            <div class="level neutral" style="padding: 1px;"><span v-if="calificaSalario == 3"><font-awesome-icon size="1x" style="color:gray;" icon="meh" /></span></div>
                                            <div class="level satisfied" style="padding: 1px;"><span v-if="calificaSalario == 4"><font-awesome-icon size="1x" style="color:gray;" icon="smile" /></span></div>
                                            <div class="level very-satisfied" style="padding: 1px;"><span v-if="calificaSalario == 5"><font-awesome-icon size="1x" style="color:gray;" icon="grin" /></span></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedSalario === 'angry' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="angry" 
                                            @click="selectedSalario = 'angry'; calificaSalario = 1;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedSalario === 'frown' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="frown" 
                                            @click="selectedSalario = 'frown'; calificaSalario = 2;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedSalario === 'meh' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="meh" 
                                            @click="selectedSalario = 'meh'; calificaSalario = 3;" 
                                            />
                                        
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedSalario === 'smile' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedSalario = 'smile'; calificaSalario = 4;" 
                                            />
                                    </td>
                                    <td>
                                        <font-awesome-icon 
                                            :style="{ color: selectedSalario === 'grin' ? 'chocolate' : 'gray' }" 
                                            size="2x" 
                                            icon="smile" 
                                            @click="selectedSalario = 'grin'; calificaSalario = 5;" 
                                            />
                                    </td>
                                </tr>
                            </table>
                        </p>
                    </div>
                    <div >
                        <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
                            <v-row>
                                <div class="form-group" style="width:450px;" >
                                    <label for="datos">Notas</label>
                                    <textarea  v-model="notas"  type="text" placeholder="Notas " style="margin-top: 10px; width: 450px; min-height: 350px;" rows="52" ></textarea>
                                </div>
                                <div class="form-group">  
                                    <label for="compensación">Compensación</label>
                                    <input v-model="compensacion" class="inputs" type="text" placeholder="Compensación" />
                                </div>
                            </v-row>
                        </v-col>
                    </div>
            </v-row>       


                    <br>
                    <v-btn @click="RegistroCandidato" color="primary">Enviar</v-btn>
            </v-container>
        </v-container>
        <!-- fin formaulario-->
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
import puestoSolicitado from "./puesto-solicitado.vue";
export default {
    components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
            selectedFuncion: null,
            selectedHabilidades: null,
            selectedIdioma: null,
            selectedHerramientas: null,
            selectedSalario: null,
            show: false,
            expiration: false,
            selectedItem: null,
            id: 0,
            idPuesto: 0,
            puestoSolicitado: "",
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            cvPdf: "",
            telefono: "",
            email: "",
            nivelCumplimiento: 0,
            rechazado: false,
            calificaFunciones: 1,
            calificaHabilidades: 1,
            calificaIdioma: 1,
            calificaHerramienta: 1,
            calificaSalario: 1,
            notas: "",
            compensacion: 0,
        }
  },
    setup() {
        
    },
    methods: {
        RegistroCandidato() {
            console.log("RegistroCandidato");
            this.Guardar();
            // regresar a a la vista anterior
            this.$router.push( { name: 'puesto-solicitado', params: { idPuesto: this.idPuesto, tituloPuesto: this.puestoTitulo }});
        },

        Guardar() {
            const url = Server+'/Candidato';
            const data = {
            id: 0,
            idPuesto: this.idPuesto,
            puestoSolicitado: this.puestoSolicitado,
            nombre: this.nombre,
            apellidoPaterno: this.apellidoPaterno,
            apellidoMaterno: this.apellidoMaterno,
            cvPdf: this.cvPdf,
            telefono: this.telefono,
            email: this.email,
            nivelCumplimiento: 0,
            rechazado: true,
            calificaFunciones: 0,
            calificaHabilidades: 0,
            calificaIdioma: 0,
            calificaHerramienta: 0,
            calificaSalario: 0,
            notas: this.notas,
            compensacion: this.compensacion,
            };

            axios.post(url, data)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        
        },
    },
    created() {
        this.show = false;
        this.idPuesto = this.$route.params.idPuesto;
        this.puestoTitulo = this.$route.params.tituloPuesto;
        // this.listar();
    },
    
}
</script>

<style >
        .satisfaction-bar {
        display: flex;
        height: 20px; /* Altura de la barra */
        border-radius: 10px; /* Bordes redondeados */
        overflow: hidden; /* Oculta cualquier contenido que se salga de los bordes */
        background: linear-gradient(to right, #ff5f5f, #ffbd4c, #fff275, #cbf169, #57e32c);
        }

        .level {
        flex-grow: 1; /* Cada nivel ocupa el mismo espacio */
        }
</style>