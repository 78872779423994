var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor",attrs:{"id":"procesos-seleccion"}},[_c('loader',{attrs:{"show":_vm.show}}),_c('sesion',{attrs:{"expiration":_vm.expiration}}),_c('Sidebar'),_c('v-container',{staticClass:"base text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"titulo ml-10 mt-5",attrs:{"no-gutters":""}},[_vm._v("Proceso de selección")])],1),_c('v-row',{staticClass:"datos pa-0",staticStyle:{"height":"auto !important"},attrs:{"no-gutters":""}},[_c('v-card',{staticStyle:{"width":"100%","background":"rgb(199, 195, 199) !important"}},[_c('v-data-table',{staticClass:"elevation-2 text--dark",attrs:{"headers":_vm.headers,"items":_vm.requisiciones,"search":_vm.search,"loading-text":"Cargando datos... Por favor espere","items-per-page":5,"no-results-text":'No se encontraron incidencias',"no-data-text":'No hay datos',"footer-props":{
          'items-per-page-text': 'Requisiciones por página',
          'items-per-page': [5, 10, 15],
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10],
          'loading-text': 'Obteniendo datos... Por favor espere',
          'no-data-text': 'No hay datos...',
          'no-results-text': 'No se encontraron incidencias',
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"#ffffff","icon":"warning"}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("No hay datos en sistema ...")])])]},proxy:true},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.Id,class:{ selectedRow: item === _vm.selectedItem }},[_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.nombrePuesto)+" ")]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(new Date(item.fechaRequeridaIngreso).toLocaleDateString()))]),_c('td',[(item.semaforo > 8)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-circle")]):(item.semaforo >= 0)?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-circle")])],1),_c('td',{staticClass:"justify-center layout px-0"},[_c('span',[(item.estatusId != 1)?_c('v-icon',{attrs:{"color":"#717171","small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v("fa-eye")]):_vm._e()],1)])])}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }