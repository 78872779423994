<template>
    <div id="procesos-seleccion" class="contenedor">
        <loader :show="show" />
        <sesion :expiration="expiration" />
        <Sidebar></Sidebar>
        <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >Proceso de selección</v-row>
        </v-container>
        <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="requisiciones"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Requisiciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{ item.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">{{ new Date(item.fechaRequeridaIngreso).toLocaleDateString() }}</td>
                  <td> 
                    <!--{{ item.semaforo }}-->
                    <v-icon v-if="item.semaforo > 8" color="green">mdi-circle</v-icon>
                    <v-icon v-else-if="item.semaforo >= 0" color="yellow">mdi-circle</v-icon>
                    <v-icon v-else color="red">mdi-circle</v-icon>
                  </td>
                  <td class="justify-center layout px-0">
                    <span>
                    <v-icon
                      color="#717171"
                      small
                      @click="viewItem(item)"
                      v-if="item.estatusId != 1"
                      >fa-eye</v-icon
                    ></span>
                   
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
    components: {
    Sidebar,
    Loader,
    Sesion,
  },
    data() {
        return {
            show: false,
            expiration: false,
            search: "",
            dialog: false,
            selectedItem: null,
            address: [],
            headers: [
                { text: "Nº", value: "id" },
                { text: "Vacante", value: "puestoVacante.nombrePuesto", sortable: true},
                { text: "Fecha de Ingreso", value: "puestoVacante.FechaRequeridaIngreso", sortable: true},
                { text: "Semaforo", value: "semaforo", sortable: false },
                { text: "Acciones", value: "actions", sortable: false },
                { text: " ", value: "CV", sortable: false },
            ],
            requisiciones: [],
            enterprises: [],
            enterpriseSelect: [],
            respuesta: "",
            advice: false,
            id: "",
            confirmation: false,
            edit: false,
            vacio: false,
            idEmpleado: localStorage.empleadoIdGlobal,
        }
    },
    methods: {
      editItem(item) {
      localStorage.requisicionId = item.id;
      localStorage.empresaId = item.puestoVacante.empresaId;
      localStorage.puestoId = item.puestoVacante.id;
      console.log(item);
      this.$router.push("/finishRequisition");
    },
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la postulante de " +
        item.puestoVacante.nombrePuesto +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/requisicionesVacantes/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta = "La vacante fue eliminada con éxito";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "La vacante no fue eliminada con éxito.";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      localStorage.requisicion = item;
      //alert(item.idPuesto);
      this.$router.push( { name: 'puesto-solicitado', params: { idPuesto: item.idPuesto, tituloPuesto: item.nombrePuesto }});
    },
    listar() {
      this.show = true;
      //alert(this.idEmpleado+"registro a traer");
      axios
        .get(

          Server + "/requisicionesVacantes/proceso-seleccion/" + this.idEmpleado,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.requisiciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    },
    created() {
        this.show = true;
         // terminar loader
         this.listar();
        this.show = false;
        
    },
};
</script>
