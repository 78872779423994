<template>
    <div id="puesto-solicitado">
        <loader :show="show" />
        <sesion :expiration="expiration" />
        <Sidebar></Sidebar>
        <v-container fluid class="base text-center">

     
      <v-row no-gutters class="titulo ml-10 mt-5"><span style="color:black"> Selección de Candidatos para el puesto: {{puestoTitulo}} </span></v-row>
        <v-row no-gutters class="titulo ml-10 mt-5">
          <br/><br/> 
        <v-btn color="rgb(199, 195, 199)" @click="AltaEntrevista">Registrar Candidato</v-btn>
     </v-row> 
      </v-container>

        <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="candidatos"
           
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Requisiciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }">
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                   {{ item.nombre }}
                  </td>
                  <td class="text-sm-center">
                   {{ item.apellidoPaterno }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.apellidoMaterno }}
                  </td>
                  <td class="text-sm-center">
                    <v-btn @click="downloadPdf(item.cvPdf)" color="primary">Descargar</v-btn>
                  </td>
                  <td class="justify-center">
                   {{ item.telefono }}
                  </td>
                  <td class="justify-center">
                   {{ item.email }}
                  </td>
                  <td class="justify-center">
                        <div class="satisfaction-bar">
                            <div class="level very-dissatisfied"> <span v-if="item.nivelCumplimiento < 5">X</span></div>
                            <div class="level dissatisfied"><span v-if="item.nivelCumplimiento > 5 && item.nivelCumplimiento < 10">X</span></div>
                            <div class="level neutral"><span v-if="item.nivelCumplimiento > 10 && item.nivelCumplimiento < 15">X</span></div>
                            <div class="level satisfied"><span v-if="item.nivelCumplimiento > 15 && item.nivelCumplimiento < 20">X</span></div>
                            <div class="level very-satisfied"><span v-if="item.nivelCumplimiento > 20">X</span></div>
                        </div>
                   {{ item.nivelCumplimiento }}
                  </td>
                  <td class="justify-center">
                    <v-btn @click="viewItem(item)" color="primary">Editar</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";

export default {
    components: {
    Sidebar,
    Loader,
    Sesion,
    },
    data() {
        return {
            show: false,
            expiration: false,
            search: "",
            dialog: false,
            puestoTitulo: "demo",
            idPuesto: 0,
            selectedItem: null,
            candidatos: [],
            idEmpleado: localStorage.empleadoIdGlobal,
            headers: [
                { text: "Id", value: "id" },
                { text: "Nombre", value: "nombre" },
                { text: "Apellido Paterno", value: "apellidoPaterno", sortable: true},
                { text: "Apellido Materno", value: "apellidoMaterno", sortable: true},
                { text: "CV PDF", value: "cvPdf", sortable: false },
                { text: "Teléfono", value: "telefono", sortable: false },
                { text: "Correo", value: "email", sortable: false },
                { text: "Nivel Cumplimiento", value: "nivelCumplimiento", sortable: false },
                { text: "Acciones ", value: "acciones", sortable: false },
            ],
        }
    },
    setup() {
        
    },
    methods:{
 
    AltaEntrevista(){
       //alert("entrevista "+this.idPuesto+" "+this.puestoTitulo);
        this.$router.push( { name: 'entrevista', params: { idPuesto: this.idPuesto, tituloPuesto: this.puestoTitulo }});
    },    

    },
    listar() {
      this.show = true;
      //alert(this.idPuesto+"Al");
      
    },

    created() {
        this.show = false;
        this.idPuesto = this.$route.params.idPuesto;
        this.puestoTitulo = this.$route.params.tituloPuesto;
        //alert(this.idPuesto+" "+this.puestoTitulo);
        axios
        .get(

          Server + "/Candidato/requisicion/" + this.idPuesto,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //alert(response.data);
          this.candidatos = response.data;
          //alert(this.candidatos.length+" "+this.candidatos[0].nombre);

          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
}
</script>

<style >
        .satisfaction-bar {
        display: flex;
        height: 20px; /* Altura de la barra */
        border-radius: 10px; /* Bordes redondeados */
        overflow: hidden; /* Oculta cualquier contenido que se salga de los bordes */
        background: linear-gradient(to right, #ff5f5f, #ffbd4c, #fff275, #cbf169, #57e32c);
        }

        .level {
        flex-grow: 1; /* Cada nivel ocupa el mismo espacio */
        }
</style>